.background_righPanel{
    display: flex;
    flex-direction: column;
    padding: 60px;
    width: 100%;
    margin-left: 20%;
}
.title_rightPanel{
    font-size: 2em;
    font-weight: 600;
}
.buttons_container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 30px 0px;
    width: 100%;
  }
  .Buy_button_card_mob{
    display: none;
  }
  
  .buttons_rightPanel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    
  }
  
  .button_rightPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 10px;
    padding: 10px;
    background-color:white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    width: 90%px;
    box-shadow: 0px 4px 9px 0px #0000000D;
  }
  
  .buttonimg_rightPanel {
    width: 100px !important;
    object-fit: cover;
  }
  
  .Buy_card {
  
    border:none;
    color: white;
    font-size: 2em;
    line-height: 30px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    width: 50%;
     background-image: url(../../assets/buy_btn_card.svg);
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
    box-sizing: border-box;
  }
  .Buy_card button{
    width: 50%;
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    padding: 10px;

  }

  .history_right_panel{
    display: none;
  }

  @media (max-width: 1124px) {

    .history_right_panel {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    
      width: 100%;
      height: 200px;
      
    }
    .history_to_scroll{
      overflow-y: auto;
    }
    
  .background_righPanel{
    margin: 0px !important;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  
.title_rightPanel{
  display: none;
}
.buttons_container{
    display: flex;
  flex-direction: column;
    gap: 0px !important;
    padding: 0px 0px;
    width: 100%;
    
  }
  
  .buttons_rightPanel {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 10px;
   margin-bottom: 10px;
  
  }
  
  .button_rightPanel {
    display: flex;
    flex-direction: column;
    align-items:start;
    justify-content: left;
    gap: 0px;
    padding: 10px;
    background-color:white;
    border: 1px solid #C0CFE0;
    border-radius: 10px;
    font-size: 13px;
    width: 90%px;
    
    text-align: left;
  }
  
  .buttonimg_rightPanel {
    width: 40px !important;
    object-fit: cover;
    
  }
  
  .Buy_card {
  display: none;
   
  }
  
.History_title_mob{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:0px !important;
  font-size: 18px;
  padding: 0;
}
.History_title_mob p{
  text-decoration: underline;
  font-size: 16px;
}

  .Buy_button_card_mob{
    display: flex;
    flex-direction: row;
    align-items:start;
    justify-content: left;
    gap: 10px;
    padding: 10px;
    background-color:#F2F2FF;
    border: 1px solid #C0CFE0;
    border-radius: 10px;
    font-size: 12px;
    width: 90%px;
    border: 1px solid rgba(128, 128, 128, 0.144);
    text-align: left;
  }
  .Buy_buttonimg_card_mob{
    width: 30px !important;
    object-fit: cover;
  }
  .Buy_buttontxt_card_mob{
    display: flex;
    flex-direction: column;
  justify-content: space-around;
  justify-self: center;
   gap: 6px;
  height: 100%;

  }
  .Buy_button_card_mob_heading{
    
   
    font-size: 1.1em;
    font-weight: 900;
  }

  .Buy_button_card_mob_subheading{
    color: #818181;
  }




  }