.card_button1{
    width: 100%; 
   margin: 0px;
   padding: 0px;
  
}

.category_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px;
    font-size: 18px;
}

.category_cards{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 10px; 
    grid-template-rows: auto; 
    margin-bottom: 20px;
    width: 100%; /* Ensures full viewport width */
    padding: 10px 0px ; /* Removes any padding */
    margin: 0 auto;
    
}
.popup_askabout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup_content_askabout {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 450px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    margin-bottom: 15%;
  }
  
  .popup_header_askabout {
    display: flex;
    justify-content: space-between;
   
  }
  
  .popup_buttons_askabout button {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: transparent;
    color: black;
    border: 1px solid rgba(128, 128, 128, 0.164);
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    text-align: left;
  }
  .Topic_askabout{

    border: 1px solid rgba(128, 128, 128, 0.76);
    font-family:Noto Sans;
    font-weight: 400;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background-color: #ededf228;
   
    padding: 10px;
    box-sizing: border-box;
  }
 .popup_buttons_askabout{
  padding: 0;
  width: 100%;
 }
  
  .close_button_askabout {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  
  }
  

  .popup_text_askabout {
   display: flex;
   flex-direction: column;
   gap: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .popup_text_askabout p {
 
    color: #9CA3AF;
    text-align: left;
  }



  .popup_text_askabout div {
   
    color: #4447E0;
    text-align: left;
    font-weight: 800;
  }
  
  .bottom_buttons_askabout{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1em;
  }
  .askyourquestion{
    width: 100%;
    align-self: center;
    background-color:#4447E0;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
  }

  .popup_viewall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup_content_viewall {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    display: flex;
    flex-direction: column; 
    gap: 10px;
  }
  .popup_header_viewall{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .popup_images_viewall{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 10px 10px; 
    grid-template-rows: auto; 
  }
  .popup_images_viewall img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .close_button_viewall {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  


  @media (max-width: 1124px) {

    .card_button1{
      width: 100%; 
      height: 150px;
      box-sizing: border-box;
    
  }
  
  .category_top{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
  
 .view_all{
  font-size: 16px;

  text-decoration: underline;
 }

  .category_cards {
    display: grid;
    grid-template-rows: repeat(1, auto); /* Two rows */
    grid-auto-flow: column; /* Forces items to flow horizontally */
    grid-gap: 10px;
    overflow-x: auto; /* Enables horizontal scrolling */
    padding: 10px 0;
    white-space: nowrap; /* Prevents wrapping */
    scroll-snap-type: x mandatory; /* Enables smooth snapping */
}

.category_cards img {
    width: 150px; /* Set a fixed width for the cards */
    height: 100px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 8px;
    scroll-snap-align: start;
}


.popup_askabout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Align to the bottom */
}

.popup_content_askabout {
  background-color: white;
  padding: 20px;
  border-radius: 20px 20px 0 0; /* Rounded top corners */
  width: 100vw; /* Full width */
  height: fit-content; /* 80% of the screen height */
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15%;
}


.popup_header_askabout {
  display: flex;
  justify-content: space-between;
 
}

.popup_buttons_askabout button {
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: black;
  border: 1px solid rgba(128, 128, 128, 0.164);
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  text-align: left;
}

.popup_buttons_askabout{
padding: 0;
width: 100%;
}

.close_button_askabout {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;

}


.popup_text_askabout {
 display: flex;
 flex-direction: column;
 gap: 10px;
  font-size: 14px;
  color: #555;
}

.popup_text_askabout p {

  color: #9CA3AF;
  text-align: left;
}



.popup_text_askabout div {
 
  color: #4447E0;
  text-align: left;
  font-weight: 800;
}

.bottom_buttons_askabout{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1em;
}
.askyourquestion{
  width: 100%;
  align-self: center;
  background-color:#4447E0;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
}
.popup_viewall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Align to the bottom */
}
.popup_content_viewall {
  background-color: white;
  padding: 20px;
  border-radius: 20px 20px 0 0; /* Rounded top corners */
  width: 100vw; /* Full width */
  height: 80vh; /* 80% of the screen height */
  display: flex;
  flex-direction: column;
  gap: 10px;
}


  
  }