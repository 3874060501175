/* Body and Background Styling */
.body_landing_page {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: white; /* White background */
    color: #333; /* Dark text color */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  /* Main container for 404 page */
  .not-found-container {
    width: 100%;
    max-width: 600px; /* Maximum width for readability */
    padding: 40px 20px;
    background-color: white; /* White background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center;
  }
  
  /* Header style */
  .not-found-container h1 {
    font-size: 4rem;
    color: #d3d3d3; /* Light gray text */
    margin-bottom: 20px;
  }
  
  /* Paragraph styling */
  .not-found-container p {
    font-size: 1.2rem;
    color: #808080; /* Light gray color for the message */
    margin-bottom: 30px;
  }
  
  /* Button styling */
  .go-home-btn {
    text-decoration: none;
    background-color: #4CAF50; /* Green background */
    color: white;
    font-size: 1.1rem;
    padding: 15px 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .go-home-btn:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  /* Optional: For better visual clarity, ensure a minimum height on mobile */
  @media (max-width: 600px) {
    .not-found-container {
      padding: 20px 15px;
    }
    .not-found-container h1 {
      font-size: 2.5rem;
    }
  }
  