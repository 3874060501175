.privacy-policy-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 9.02px 36.09px 0px #7C7C7C14;

  }
  
  .privacytopdiv {    
    margin-top:5px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 3px solid #7896be52 ;
  }
  
  .back-button { 
    color: var(--main-color);
    font-size: 16px;
    font-size: 1.8em;
    gap: 20px;
    top: 10px;
    left: 10px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
  }
  
  #policy-content {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1em;
    line-height: 1.2;
    margin-top: 20px;
    font-family: Noto sans;
    font-weight: 500;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: flex-end;
    
  }
  
  .action-button {
    background-color:  var(--main-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }


  @media(max-width:600px){


    .back-button {
      font-size: 1.2em;
      gap: 5px;
    }

    .privacy-policy-container {
      margin: 10px;
      margin-bottom: 60px;
    }

    .button-container {
      height: 5vw;
      width: 20%;
      gap: 10px;
      justify-content: center;
    }


      #policy-content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
  }
  

  