.create_background{
    display: flex;
    flex-direction: row;
    
}
.create_main{
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    gap: 10px;
    width: 100%;
    margin-left: 20%;

}
.create_title{
    font-size: 2em;
    font-weight: 600;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}
.create_inputbox1{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 700;
    margin-bottom: 15px;
}
.create_input_1{
    border: 1px solid rgba(128, 128, 128, 0.76);
    font-family:Noto Sans;
    font-weight: 400;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background-color: #ededf228;
   
    padding: 10px;
    box-sizing: border-box;

}



.academic-level-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
  }
  
  .academic-level-label {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .academic-level-button-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  
  .academic-level-button {
    flex: 1;
    padding: 10px 20px;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
    transition: all 0.3s ease;
    outline: none;
    background-color: white;
    color: black;
  }
  
  .academic-level-button.selected {
    background-color: #4447e0;
    color: white;
  }
  
  .academic-level-button:hover {
    background-color: #4447e0;
    color: white;
  }
  
  .academic-level-button:active {
    background-color: #3a40c5;
    color: white;
  }
  
  
.app_outline {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  

  .generate-outline_label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  

  .buttons-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  

  .button-outline {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    width: 350px; 
    justify-content: left;
    transition: all 0.3s ease;
  }
  
  .button-outline.selected {
    border-color: #4447E0;
  }
  
  
  
  /* Circle styles */
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid gray;
    background-color: transparent;
  }
  
  .circle.selected-circle {
  
    border: 3px solid #4447E0;
  }
  
  /* Icon and text styles */
  .button-icon {
    width: 30px;
    height: 30px;
  }
  
  .button-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 5px;
  }
  
  /* Upper Line Text (Bold and Larger) */
  .button-text-upper {
    font-weight: bold;
    font-size: 16px;
  }
  
  /* Lower Line Text (Thin) */
  .button-text-lower {
    font-weight: normal;
    font-size: 10px;
    color: #777;
  }
  
  /* Responsive layout for small screens */
  @media (max-width: 768px) {
   .create_main{
    margin-left: 0px;
   }

.create_title{
    font-size: 1.5em;
    font-weight: 600;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.create_inputbox1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 600;
}
.create_input_1{
    border: 1px solid rgba(128, 128, 128, 0.76);
    font-family:Noto Sans;
    font-weight: 400;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background-color: #ededf228;
   
    padding: 10px;
    box-sizing: border-box;

}

.notice_topic{
  font-size: 0.8em;
 color: rgba(128, 128, 128, 0.76);
}
.notice_topic b{
  font-size: 1em;
  color:black;
}

.academic-level-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    margin-bottom: 15px;
  }
  
  .academic-level-label {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .academic-level-button-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  
  .academic-level-button {
    flex: 1;
    padding: 10px 20px;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
    transition: all 0.3s ease;
    outline: none;
    background-color: white;
    color: black;
  }
  
  .academic-level-button.selected {
    background-color: #4447e0;
    color: white;
  }
  
  .academic-level-button:hover {
    background-color: #4447e0;
    color: white;
  }
  
  .academic-level-button:active {
    background-color: #3a40c5;
    color: white;
  }
  
  
.app_outline {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
  }
  
.generate-outline{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
  .generate-outline_label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  

  .buttons-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  

  .button-outline {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    width: 350px; 
    justify-content: left;
    transition: all 0.3s ease;
  }
  
  .button-outline.selected {
    border-color: #4447E0;
  }
  
  
  
  /* Circle styles */
  .circle {
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid gray;
    background-color: transparent;
  }
  
  .circle.selected-circle {
  
    border: 3px solid #4447E0;
  }
  
  /* Icon and text styles */
  .button-icon {
    width: 40px;
    height: 40px;
  }
  
  .button-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 5px;
  }
  
  /* Upper Line Text (Bold and Larger) */
  .button-text-upper {
    font-weight: bold;
    font-size: 16px;
  }
  
  /* Lower Line Text (Thin) */
  .button-text-lower {
    font-weight: normal;
    font-size: 10px;
    color: #777;
  }
  }
  
  .generate_btn{
    align-self: center;
    width: 35%;
    margin-top: 60px;
    margin-bottom: 30px;  
     
}
  