.repeating-group {
  padding: 10px; /* Add padding for spacing */
  border: 1px solid #ddd; /* Optional: visual boundary */
  border-radius: 15px; /* Optional: rounded corners */
  display: flex;
  flex-direction: column; 
  gap: 10px;/* Use flexbox for layout */
  justify-content: space-between; /* Space between text and icons */
  align-items: left; /* Center items vertically */
  overflow: hidden;
  min-height: 90px; /* Prevent overflow */
  max-height: 100px; 
  margin-bottom: 10px;/* Set max height for consistent sizing */
}

.repeating-group span {
   /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  /* Allow the text to take up available space */
}

.icon-container {
  display: flex; /* Flexbox for icons */
  align-items: center; 
  gap: 20px;/* Center icons vertically */
}
.copy_share_btn_history{
  background-color: #EBF4FF;
  border-radius: 20px;
  color: #1885FF;
  padding: 8px 15px;
  font-family: DM Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
}


.history_text{
  font-family: DM Sans;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.005em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;


  
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;

}

.history_null {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
height: 100vh;
}

.history_null img{
  height: 50px;
  width: 50px;
}
.history_box{
  padding: 40px;
  margin-top: 10px;
}

.leftpanel_history{
  display: block;
}
@media (max-width: 1124px) {
  .leftpanel_history{
   margin-bottom: 10px;
  }


  .history_box{
    padding: 0px !important; 
    margin: 0px !important;
  }

 
.history_null {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center; 
  justify-self: center; 
     width: 150px;
    height: 100px;
  color: #B7B9BD;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
}