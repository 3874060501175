.tab-container {
    height: fit-content;
    
    width: 100%;
}


@media(max-width:391px){
    .tabs-btn { 
        font-size: 14px !important;
    }

}
@media(max-width:361px){
    .tabs-btn { 
        font-size: 12px !important;
    }

}
.tabs-btn { 
    margin-top: 12px;
    display: inline-flex;                    
    align-items: center;                     
    background-color: transparent;
    margin-right: 10px; 
    font-family:Noto Sans; 
    font-size: 16px;
    font-weight: 700;
    color: #9AA4B8;        
    height: 30px;                
    border: none;    
    transition: color 0.3s, border-color 0.3s; 
    position: relative;  
}

.tabs-icon {
    margin-right: 5px;              
    transition: filter 0.3s;      
}

.tabs-btn.active {
    color: var(--main-color);   
    border-bottom: 2px solid var(--main-color); 
}




@media (max-width: 600px) {
    .tab-container {
        margin-top: 30px !important;
        width: 100%;
    }

    .tabs-btn {
        margin-top: 5px;
        display: inline-flex;                   
        align-items: center;
        background-color: transparent;
        margin-right: 5px; 
        font-family: 'Noto Sans', sans-serif;
        font-size: 1em;
        font-weight: 600;
        color: #9AA4B8;        
        height: 20px;                
        border: none;    
        transition: color 0.3s, border-color 0.3s;  
        padding-bottom: 5px;
    }
}
