*{   
    box-sizing: border-box;
    font-family: Inter !important;
    font-weight: 500;
    padding: 0px;  
    
    margin: 0px;
}
body{
    background-color: white;
    padding: 0px;  
    max-width: 1900px;
    margin: 0px;
    justify-content: center;
}
.App{
    display: flex;
    flex-direction: row;
}
.LeftPanel {
    position: fixed;
    width: 20%; /* Adjust as needed */
    height: 100vh;
    background: lightgray;
  }
  
  .RightPanel {
    margin-left: 20%; /* Ensures it starts after LeftPanel */
    flex-grow: 1; /* Takes remaining space */
    padding: 20px;
  }
.seperation-div{
    margin-left: 20%;
    margin-left: 20%; /* Ensures it starts after LeftPanel */
    flex-grow: 1; /* Takes remaining space */
    padding: 20px;
} 
.main_panel.generation {
    flex: 0 1 auto;
    margin-top: 10px !important;  
    margin-left: 22%;  
    width: 70%;
    height: fit-content !important;
    min-height: 90vh;
    background-color: white;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 15px!important ;
    padding-right: 20px;
}

.buttonscrollonslider{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
}
.error {
    border: 1px solid red !important; 
  }
  
  .error-message {
    color: red; /* Error message color */
    font-size: 12px; /* Adjust font size */
    margin-top: 5px; /* Spacing */
    text-align: left;
font-family: Noto Sans ;}
@media(max-width:390px){

.h1{
    width: 100%;
    font-size: 1em;
}
.proicon{
    height: 2vh !important;
}



    }

    @media(max-width:360px){

        .h1{
            width: 100%;
            font-size: 1em;
        }
        
        
        
            }


            @media(max-width:360px){

                .main_panel.generation{
                    padding-left: 2px !important;
                    padding-right: 4px !important;
                }   
                }

@media(max-width:600px){
   
    
    .seperation-div{
        margin: 10px;
        padding: 10px;
        box-shadow: 0px 9.02px 36.09px 0px #7C7C7C14;
    }
    
    .history_box{
        padding: 10px !important;
      }
    body{
        padding: 0 !important;
        margin: 0px !important;
    }
    .username_text{
        display: none !important;
    }
    .topic{
        margin-top: 10px;
    }
    .main_panel{
        margin: 15px !important;
        padding-top: 0px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
        width: 90% !important; 
        min-height: 75vh !important;
         
    }
    .proicon{
        width: 8vw !important; 
        align-items: center;
      }
    .main_panel.generation{
        padding-left: 4px !important;
        padding-right: 6px !important;
    }
    .left_panel{
        
        display:none !important ; /* Hide the element */
    }
    
    .div_input{
        flex-direction: column;
        margin-top: 15px !important;

    }
    .header_mobile{
        display: flex !important;
        
    }
  

    .title_name {
        font-size: 1.4em !important ; 
        margin-bottom: 1px;
        display: flex;
        align-items: center;
        color:  var(--main-color);
        gap: 10px;
    }

    .username_text {
        font-size: 1em !important; /* Adjust font size for smaller screens */
        margin-top: 0.5rem; /* Add some space above the text */
    }

    .title_main_page {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start !important; /* Align items to the start */
        text-align: left; /* Ensure text is left-aligned */
        gap: 0px;
    }
   
   
.logo_title{
    display: inline-block!important;
    height: 5vh;    
}

.input_1{
    font-family: Noto Sans;
    font-weight: 400;
    margin-bottom: 5px;   
    height: 100px !important;
    border-radius: 8px;
    background-color: #ededf24b;
    border: none;
    padding: 10px;
    font-size: 0.8em;
    
}
.input_1::placeholder {
    color: rgba(143, 138, 138, 0.377); /* Change the color and adjust opacity */
}

.input_2{
    width: 100% !important;
    height: 7vh;
    border-radius: 8px;
    background-color: #f8f8ff;
    border: none;
    padding: 10px;
    box-sizing: border-box;
}

.div_input{
    margin-top: 10px;
    display: flex !important;
    flex-direction: column;
    gap: 30px !important;
    
}

.generate_btn{
    width: 100% !important;
    margin-top: 40px; 
    margin-bottom: 40px;  
     
}

}

.no-scroll {
    overflow: hidden !important;
}


.header_mobile{
    background-color:white;      
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 8vh;
    display: none;
    justify-content: left; 
    gap: 20px;
    align-items: center; 
    padding: 20px;

}
.history_btn_header{
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.history_btn_header img{
    width: 20px;
    height: 20px;
}
#login_header{
    font-size: 0.8em;
    font-family:Noto Sans; 
    color: white;
    border: 2px solid white;
    border-radius:150px;
    background-color: transparent;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
}
#logout_header{
    font-size: 0.7em;
    font-family:Noto Sans; 
    color: white;
    border: 2px solid white;
    border-radius:150px;
    background-color: transparent;
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;

}


.logo_left_panel{
    width:5.3vw;
    
    
}

.login_crown_container{
    font-family:Noto Sans; 
    width: 110px;
    display: grid;
    grid-template-columns: auto auto; /* Two columns */
    align-items: center; 
    gap: 5px;
}

.left_panel{
    height: 100vh;
    position: fixed;
    width: 19%;
    background:  white ;
    border-radius: 10px;
    
    padding-top: 40px;  
    display: flex;
    flex-direction: column; 
    gap: 5px;  
    align-items: center;
    
    }

.left_title {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family:Noto Sans;
    font-size: 1.6vw;
    color:var(--main-color);
    font-weight: 700;
}

.buy_btn {
    width: 50px ;
    margin-bottom: 10px;
}
.buy-btn-img-left-panel {
    max-width: 65vw;
    width: 16vw;
    border-radius: 50px;
    border: none;
    padding: 8px;
    background-color:var(--main-color);
    color: white;    
    height: auto; 
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-family: Noto Sans;
    font-size: 1vw;
  }
  .panelcrown{
    width: 1.5vw;
  }

.scroll_btn{
    justify-self: left;
    background-color: transparent;
    text-align: left;
    color: #DADDDE;
    border: none;
    font-size:1.4vw;
    display: flex;
    justify-content: left;
    align-items: center;      
    gap:10px;
    height: 5%;
    transition: color 0.3s;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    
}

.scroll-img{
    width: 2vw;
}

.scroll_btn:hover {
    color:var(--main-color); 
}

.scroll_btn:hover .scroll-img{
    filter:  brightness(0) saturate(100%) invert(73%) sepia(19%) saturate(3094%) hue-rotate(279deg) brightness(92%) contrast(96%);; 
}

.scroll_btn.active .scroll-img{
    filter: brightness(0) saturate(100%) invert(73%) sepia(19%) saturate(3094%) hue-rotate(279deg) brightness(92%) contrast(96%);; 
}

.scroll_btn.active {
    color: var(--main-color);
    border-right: 2px solid var(--main-color);
   
    
}

#login_left{
  margin-top: 40%;
  width: 10vw;
  background-color: transparent;
  color: var(--main-color);
  overflow: hidden; 
  white-space: nowrap; 
  margin-bottom: 120px;
  font-size: 1em;
    font-family:Noto Sans; 
    border: 2px solid var(--main-color);
    border-radius:150px;
    background-color: transparent;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.buy_btn_left{
   
    height:10% ;
    background-color: transparent;
    border: none;
    text-align: left;
}

.main_panel{    
    flex: 0 1 auto;
    margin-top: 0px;  
    margin-left: 22%;  
    width: 70%;
    height: fit-content !important;
    min-height: 90vh;
    background-color: white;
    border-radius: 10px;
    padding-left: 50px;
    padding-top: 15px;
    padding-right: 60px;
    
    
}
#container {
    display: flex;
    justify-content:space-between; 
    padding: 0;
    margin: 0;
    
}


.logo_title{
    display: none;


}
.proicon{
    width: 4vw;
}
.title_main_page {
    margin-top: 20px;
    margin-bottom: 15px;
    height:30px;
    width: 100%;
    display: flex;
    justify-content:space-between; 
    align-items: center;  }

.title_name{
    font-family:Noto Sans;
    font-size: 2.6vw;
    font-weight: 700;
    height: fit-content;
}   

.username_text {
    font-family: Noto Sans;
    font-size: 1vw;
    color: #777E90;
    font-weight: 600;
    background-color: transparent;
    border: none;

}

.form{
    margin-top: 20px;  
    text-align: center;      
     
}
.topic{
    font-size: 18px;
    font-family:Noto Sans; 
    font-weight: 700;
    margin-bottom: 5px;
    text-align: left; 
}



.input_3{
    font-family:Noto Sans;
    font-weight: 400;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: none;
    background-color: #ededf259;
    padding: 10px;
    box-sizing: border-box;
    color: black; /* Change this to your desired color */
   
    
}

.input_1:hover,
.input_1:focus {
  border:none; 
  outline: none; 
}


.input_2:hover,
.input_2:focus {
  border:none; 
  outline: none; 
}
.div_input{
    margin-top: 30px;
    display: inline-flex;
    gap: 10px;
}
.input_2{
    font-family:Noto Sans;
    width: 30.5vw;
    height: 7vh;
    border-radius: 8px;
    background-color: #ededf259;
    border: none;
    padding: 10px;

}





.hisotry_null{

    text-align: center;
}




.hamburger {
    cursor: pointer;
    font-size: 30px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    z-index: 1000; 
  }
  
  .overlay-content {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
  }
  
  .topslider {
    height: 30%;
    position: relative;
    background:  var(--main-color) ;
    display: flex;
    justify-content: center; 
    align-items: flex-end; 
    padding: 10px;
  }
  .topslider  img{
        width: 100px;
  }
  
  .close-icon {
    cursor: pointer;
    color: white;
    font-size: 22px; 
    position: absolute; 
    top: 30px; 
    right: 30px; 
    
  }
  
  .frame {
    height: 16vh; 
    display: block;
    margin-bottom: 5px;
    justify-self: center;
  }

  .slidercrown{

    width:35px ;
  }

  .buy-premium-slider{
    font-family: Noto Sans;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 80%;
    border-radius: 4dvb;
    border: none;
   font-size: 1.1em;
    color: white;
    background-color: var(--main-color) ;;
  }
  
  .button-container-slider {
    display: flex;
    flex-direction: column;    
    flex-grow: 1;
    align-items: center;
    gap: 25px;
  }
  .slider-Homeimg{
    height: 3.5vh;
  }
  
  .Home_btn_slider{
    color: #3F4141;
    background-color: transparent;
    border: none;
    font-size: 1.2em;
    font-family: Noto Sans;
    font-weight: 500;
    display: flex;
   align-items: center;
   justify-content: left;
    gap: 10px;
    width: 70%;
  }
  .Home_btn_slider.active {
    color: var(--main-color); 
   
}

  
  .action-button {
    margin: 10px;
    padding: 15px 30px;
    font-size: 18px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  #login_slider{
    margin-top: 40%;
    font-size: 1em;
    font-family:Noto Sans; 
    border-radius:150px;
    background-color: transparent;
    padding: 8px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: transparent;
    color: var(--main-color) ;;
    border: 2px solid  var(--main-color) ;;    
    margin-bottom: 120px;
    width: fit-content;
   
  }
  


  .full-screen-overlay {
    display: flex; /* Use flexbox for centering */
    justify-content: center;
    align-items: center;
    position: fixed; /* Cover the whole screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Slightly transparent white */
    z-index: 999; /* Just below the loader */
}

/* Loader styles */
.loader {
    background-color: rgba(255, 255, 255, 0.8); /* White background with slight transparency */
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid  var(--main-color); /* Color for the spinner */
    border-radius: 50%;
    width: 60px; /* Size of the spinner */
    height: 60px; /* Size of the spinner */
    animation: spin 1s linear infinite; /* Animation */
    z-index: 1000; /* Ensure it appears above other content */
}
/* Prevent scrolling while loading */
body.loading {
    overflow: hidden; /* Prevent scrolling */
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.floating-button {
    position: fixed;
    right: 0px; /* Distance from the right */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
    z-index: 1000; /* Ensure it stays above other elements */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .floating-button img {
    width: 40px; /* Adjust size as needed */
    height:250px; /* Adjust size as needed */
  }