.landing-page {
  font-family: Inter !important;
    height: 100%;
    width: 100%;
  }
  
  
  /* General header styles */
.header-landing-page {
  display: flex;
  justify-content: space-between;  
  align-items: center;
  padding: 20px 80px;
  position: fixed; /* Fixes the header at the top */
  top: 0; /* Aligns it at the top of the page */
  left: 0; /* Aligns it to the left */
  width: 100%; /* Ensures the header stretches across the entire width */
  z-index: 1000; 
  background-color: white
}

.header-left-landing-page {
  display: flex;
  gap: 10px;
}

.logo-landing-page {
  width: 40px; /* Adjust the size of the logo */
  height: 40px;
}

.title-landing-page {
  font-size: 2em;
  font-weight: 800 !important;
  margin-top: 3%;
  font-family: Inter;
  color: #4447E0;
}

.header-middle-landing-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  gap: 10px;
}

.header-btn-landing-page {  
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.header-btn-landing-page:hover {
  background-color: #4447E0;
  color: white
}

.header-right-landing-page #login-btn-landing-page {
  background-color: #4447E0;
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
}





#hamburger-menu_hero._hero {
  display: none;
   
}

.Hero_landing-page{
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  gap: 20px;
  padding: 65px;

}
.Hero_landing-page_blogs{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 20px;
  padding: 65px;

}
.Hero_landing-text_box{
  display: flex;
  flex-direction: column;
width: 100%;
padding: 50px 10px;
gap: 20px;
}



.Hero_landing-page div{
  height: fit-content;
}
.Hero_title{
  font-size: 2.9em;
  color: black;
  font-weight: 700;
  line-height: 1.5;
  
}

.Hero-subtitle-pc{
  display: block;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.3;
  width: 50%;
}
.Hero-subtitle-mobile{
  display: none;
}


.view-dashboard{
 
  background: #4447E0;
  padding: 12px 10px;
  color: white;
  font-size: 1.em;
  border: none;
  border-radius: 10px;
  width: 40%;
  text-align: center;
}
/* .heroimage{
  height: 40%;
  width: 60%;
} */
.heroimage {

  width: 50%;
  opacity: 0;
  display: inline-block; /* Initially invisible */
  transform: translateY(50px); /* Start slightly below */
  transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
}
.heroropeimage{
  width: 5%;
}

/* Class that will be added once the image is in the viewport */
.heroimage.visible {
  opacity: 1;
  transform: translateY(0); /* Slide to its natural position */
}


.hero2ndpart_container{
  display: flex;
  flex-direction: row;
  padding: 0% 10%;
}

.hero2ndpart_left{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.hero2ndpart_left_title{
  font-size: 2.5em;
}

.hero2ndpart_left_sub_heading{
  font-size: 1em;
  font-weight: 300;
}

.hero2ndpart_left_rating{
    gap: 10px;
  display: flex;
  flex-direction: row;
}
.hero2ndpart_leftrating{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.hero2ndpart_right{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.hero2ndpart_right_container{
  display: flex;
  flex-direction: row;
gap: 10px;
}
.hero2ndpart_righttxt{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hero2ndpart_righttxt_heading{
  font-family: Inter;
  font-size: 1.5em;
  font-weight: 700;
}

.steps_box{
  padding:65px;
 width: 100%;
display: flex;
flex-direction: column;
gap: 60px;
}

.steps_heading{
  display: flex;
  flex-direction: column;
 gap: 10px;
  align-items: center;
}

.steps_title{
  font-size: 2.5em;

}
.steps_subtitle{
  font-size: 1.5em;  
  font-weight: 300;
  text-align: center;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
}

.step_number{

color: #4447E0;
border: none;
align-self: center;
font-size: 5em;
height: fit-content;
padding: 20px;
width: fit-content;
}
.steps_text_title{
  font-size: 1em;
  width: fit-content;
  line-height: 30px;
}
.steps_text_detail{
  font-size: 1em;
color: #12121280;
line-height: 25px;
  width: 100%;
  text-align: left;
 
}
.steps_each_box {
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
width: 80%;
}


.all_steps{
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: center;
   height: fit-content;
  background-position:center;
  width: 70%;
  
}
.steps_text{
  height: fit-content;
  width: 40%
}
.steps_1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.steps_2{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.steps_3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.steps_4{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}


.subscription{

  align-items: center;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subscription_title{
  width: 50%;
  font-size: 2em;
}
.subscription_subtitle{
  width: 50%;
  font-size: 1.2em;
  color: #A6A6A6;
}
.plans_wrapper{
  background-color: white;

  border-radius: 20px;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
}

.plan-1,.plan-2,.plan-3{
  box-shadow: 0px 4px 9px 0px #0000000D;
  
  border: 1px solid #C0CFE0;
  width: 40%;
margin: 20px;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 20px;
text-align: left;
border-radius: 10px;
padding: 20px;
max-width: 350px;
}
.plan-1:hover,.plan-2:hover,.plan-3:hover{
 
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
  background:#4447E0;
   
}
.plan-1:hover .points_list_full,
.plan-2:hover .points_list_full,
.plan-3:hover .points_list_full {
  background: white; 
  color: black;
  border-radius: 10px;
  padding: 10px;
}


.plan-1:hover,.plan-2:hover,.plan-3:hover .discription{
  color: white;
}

/* .plan-3{
  margin: 20px;
display: flex;
flex-direction: column;
gap: 20px;
text-align: left;
background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
border-radius: 20px;
padding: 20px;
max-width: 25%;
}*/

.plan-1:hover .price1, .plan-2:hover .price2, .plan-3:hover .price3{
  
  background: white;
  -webkit-background-clip: text; 
  background-clip: text;
  color: transparent; 
  font-family: Outfit;
  font-weight: bolder;
} 


.plan-1:hover .discription_subtitle1, .plan-2:hover .discription_subtitle2, .plan-3:hover .discription_subtitle3{
color: white;
}


.price1,.price2,.price3{
  font-size: 2em;
  color: black; 
  font-family: Outfit;
  font-weight: bolder;
  text-align: center;
}
.white_check1,.white_check2,.white_check3{
  display: none !important;
}
.price_details{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}
.price_interval,.price_interva2,.price_interva3{
  color:#84819994 ;
}

.plan-1:hover .price_interval,.plan-2:hover .price_interval2,.plan-3:hover .price_interval3{

color: white;
}




.discription1,.discription2,.discription3{
  display: flex;
  flex-direction: column;
  text-align: center;
  color:#A6A6A6 ;
  gap: 10px;
  margin-bottom: 10px;
}

.discription_subtitle{
  color: #848199;
}


.ponits_list1,.ponits_list2,.ponits_list3{
  color:black;
  display: flex;
  flex-direction: row;
  gap: 10px;
  
}

.points_list_full{
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
}
.plan-1:hover .choose_plan1,.plan-2:hover .choose_plan2,.plan-3:hover .choose_plan3{
  background-color: white;
  color: #4447E0;
}
.choose_plan1,.choose_plan2,.choose_plan3{
 background-color: #4447E0;
 font-size: 1.2em;
 color: white;
 border: 1px solid gray;
 border-radius: 10px; 
 padding: 5px;
 width: 200px;
 align-self:center;
  
}
.MOSTPOPULAR{
 background-color: white;
 color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  border: none;
   border-radius: 20px;
   text-align: right;
   width: fit-content;
   padding: 8px;
  font-family: Outfit;
  font-weight: 800;
  justify-self: end; 
}

.faq-container_hero {
  background-color:white;
  width: 100%;
  padding: 5%;
  color: black;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center; 
  align-items: center;     
  
}

.faq-title_hero {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Outfit;
}


.faq-items_hero {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  
}


.faq-item_hero {
  background-color: #FFFFFF0F; 
  padding: 10px 30px; 
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: fit-content;
}



.faq-question_hero {
  display: flex;
  gap: 40px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.plus-icon_hero {
  font-size: 1.5rem;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}


.faq-question_hero.open .plus-icon_hero {
  transform: rotate(45deg); 
}


.faq-answer_hero {
  margin-top: 20px;
  padding-top: 10px;
  font-size: 1em;
  color:white; 
  
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  max-height: 0; 
  opacity: 0; 
  overflow: hidden; 
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


.faq-item_hero.active .faq-answer_hero {
  max-height: 1000px; 
  opacity: 1;
}


@media (max-width: 1124px) {
  .faq-container_hero {
    background-color:white;
    width: 100%;
    padding: 10px;
    color: black;
  
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center; 
    align-items: center;     
    
  }
  
  .faq-title_hero {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: Outfit;
  }
  
  
  .faq-items_hero {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    
  }
  
  
  .faq-item_hero {
    background-color:#F6F6FF; 
    padding: 5px 30px; 
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-height: 50px !important;
  }
  
  
  
  
  
  .faq-question_hero {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .plus-icon_hero {
    font-size: 1.5rem;
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  
  .faq-question_hero.open .plus-icon_hero {
    transform: rotate(45deg); 
  }
  
  
  .faq-answer_hero {
    margin-top: 20px;
    padding-top: 10px;
    font-size: 1em;
    color:black; 
    line-height: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    max-height: 0; 
    opacity: 0; 
    overflow: hidden; 
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  
  .faq-item_hero.active .faq-answer_hero {
    max-height: 1000px; 
    opacity: 1;
  }
  
}

.footer{
  background-color: #161C28;
}

.footer_terms{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  font-size: 1em;
  color: white;
  font-family: Outfit;
  border-top:1px solid rgba(255, 255, 255, 0.24) ;
}


.footer_top{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  justify-self: center;
  width: 70%;
  padding-bottom: 10px;
}

.form_footer{
  width: 40%;
  border-right:1px solid rgba(255, 255, 255, 0.295) ;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.product_footer{
  border-right:1px solid rgba(255, 255, 255, 0.295) ;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
 
}
.contact_footer{
  
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer_headings{
  color: white;
  font-family: Outfit;
  font-size: 1.5em;
  font-weight: 600;
  word-wrap: break-word;
  
  
}
.footer_subheadings{
  color: white;
  font-size: 1em;
  cursor: pointer;
}

@media (max-width: 1124px) {

  #login_dropdown_hero{
    background-color: white;
    border: none;
    font-size: 1em;
    color: Black;
    
  }

  .header-landing-page {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; 
    padding: 20px 20px;
    align-items: center;
    
  }
  
  .header-left-landing-page {
    display: flex;
    margin: 0 auto;
    gap: 10px;
    align-items: center;
    justify-content:center;
    flex-grow: 1; /* Align the items to the right */
  }
  
  .logo-landing-page {
    width: 40px; /* Adjust the size of the logo */
    height: 40px;
  }
  
  .title-landing-page {
    font-size: 20px;
    color: black;
    font-weight: 600 !important;
    
  }
  
  .header-middle-landing-page {
    display: none;
    justify-content: space-between;
    width: 40%;
    gap: 10px;
  }
  
  .header-btn-landing-page {  
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  .hamburger-menu-container {
    display: flex;
    justify-content: flex-end;  /* Align hamburger to the right */
    align-items: center;  /* Center hamburger icon vertically */
  }
  .header-btn-landing-page:hover {
    background-color: #3700b3; 
  }
  
  .header-right-landing-page #login-btn-landing-page {
    display: none;
    background-color: transparent; 
    color: white;
    border: 1px solid white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 25px;
  }
  
 
  /* Basic styling for the hamburger menu container */
#hamburger-menu_hero._hero {
  display: block;
  margin-left: auto; 
}

/* Hamburger icon styling */
.hamburger-icon_hero {
  font-size: 30px;
  cursor: pointer;   
  border-radius: 5px;
}

/* Dropdown content (hidden by default) */
.dropdown-content_hero {
  position: absolute;
  left: 0; /* Align it to the right of the hamburger icon */
  top: 55px; /* Position it just below the hamburger icon */
  background-color:white;
  border-radius: 5px;

  padding: 10px 0;
  width: 200px; /* Adjust the width of the dropdown */
}

/* Dropdown options styling */
.dropdown-content_hero a {
  color: black;
  text-decoration: none;
  padding: 8px 20px;
  display: block;
}


  
  
  
  .Hero_landing-page{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 20px;
    padding: 0px;
   margin-top: 90px;
   margin-bottom: 50px;
  
  }
  .Hero_landing-page_blogs{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding: 0px;
   margin-top: 90px;
   margin-bottom: 50px;
  
  }

  .Hero_landing-text_box{
    display: flex;
    flex-direction: column;
  align-items:left;
  padding: 20px 20px;
  gap: 24px;
  }
  
  .Hero-subtitle-pc{
    display: block;
    font-size: 1em;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 26px;
    width: 100%;
  }
  .Hero-subtitle-mobile{
    display: none;
  }
  
  .Hero_title{
    font-size: 1.6em;
    width: 90%;
  }
  .Hero_title_border{
    width: 100px;
  }
  .Hero-subtitle{
    font-size: 0.6em !important;
    align-self: left;
  
    font-weight: 300;
    width: 90%;
    margin: 0px;
  }
  
  
  .view-dashboard{
   justify-self: left;
   font-weight: 500;   
    background: #4447E0;
    color: white;
    font-size:13px;
    line-height: 20.78px;
    border: none;
    
    border-radius: 12px;
  }
  .heroimage{
    
    width: 80%;
  }
  .heroropeimage{
   width:10% ;
  }
  
  .video_showcase{
 
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10% 10%;
  justify-content: center;
  overflow: hidden;
  }
  .Video_showcase_text_box{
    width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .video_showcase_text_heading{
    color: white;
    font-size: 2em;
  }
  .video_showcase_text_subheading{
    color: rgba(255, 255, 255, 0.5);
  }
 
  .video_showcase_video {
    width: 40% !important; /* Width of each video */
    height: fit-content !important; /* Height of each video */
    flex-shrink: 0; /* Prevent videos from shrinking */
    margin-left: 10px;
    aspect-ratio: 9/16;
    border-radius: 10px !important;
     /* Gap between videos */
  }
  
  /* Animation: Right-to-Left then Left-to-Right */
  @keyframes scrollRightToLeft {
    0% {
      transform: translateX(100%); /* Start at the far right */
    }
    100% {
      transform: translateX(-100%); /* Move to the far left */
    }
  }
  
  @keyframes scrollLeftToRight {
    0% {
      transform: translateX(-100%); /* Start at the far left */
    }
    100% {
      transform: translateX(70%); /* Move to the far right */
    }
  }
  
  
  
  
  .steps_box{
    padding:50px 20px !important;
   background-color: #F6F6FF;
  display: flex;
  gap: 32px;
  flex-direction: column;
 align-items: left;
  }
  
  .steps_heading{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: start;
    gap: 20px;
  }
  
  .steps_title{
    font-size: 24px;
    font-weight: 300;
    line-height: 31px;
    font-style: italic;
  }
  .steps_subtitle{
    font-size: 16px;  
    font-weight: 500;
    line-height: 30px;
    text-align: left;
   font-style:inherit;
   width: 100%;
  
  }
  .steps_subtitle br {
    display: none;
}

  
  .step_number{
  align-self: start;
  color: #4447E0;
  border: none;
  font-weight: 700;
  line-height: 87.77px;
  font-size: 72px;
  height: fit-content;
  padding: 0;
  width: fit-content;
  }
  .steps_text_title{
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    width: 80% !important;
  }
  .steps_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .steps_text_detail{
    font-size: 16px;
    font-weight: 500;
  color: #000000;
    line-height: 28px;
    width: 100%;
    text-align: left;
   
  }
  .steps_each_box {
    display: flex;
    flex-direction: column;
  
    align-items: center; 
    width: 100%;
  
  }
  
  
  .all_steps{ 
    background-position:center;
    width: 100%;
    align-self: center;
  }
  
  
  .steps_3,.steps_1,.steps_2,.steps_4 {
    justify-self: left;
    align-self: start;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
    margin-trim: 0vh !important;
  }

  
  .subscription{
   margin-top: 10px;
    align-items: center;
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .subscription_title{
    width: 100%;
    font-size: 26px;
    line-height: 40px;
    font-weight: 700;
    font-style: italic;
  }
  .subscription_subtitle{
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color:#000000;
  }
  .plans_wrapper{
    background-color: white;
    
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
  }
  
  .plan-1,.plan-2,.plan-3{
     border: 1px solid #C0CFE0;
    width: 90%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    text-align: left;
    border-radius: 10px;
    padding: 15px;
    max-width: 350px;
  }
  .plan-1:hover,.plan-2:hover,.plan-3:hover{
   
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    text-align: left;
    border-radius: 10px;
    padding: 15px;
    max-width: 350px;
    background:#4447E0;
     
  }
  .plan-1:hover .points_list_full,
  .plan-2:hover .points_list_full,
  .plan-3:hover .points_list_full {
    background: white; 
    color: black;
    border-radius: 10px;
    padding: 10px;
  }
  
  
  .plan-1:hover,.plan-2:hover,.plan-3:hover .discription{
    color: white;
  }
  
  /* .plan-3{
    margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  background: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
  border-radius: 20px;
  padding: 20px;
  max-width: 25%;
  }*/
  
  .plan-1:hover .price1, .plan-2:hover .price2, .plan-3:hover .price3{
    
    background: white;
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
    font-family: Outfit;
    font-weight: bolder;
  } 
  
  
  .plan-1:hover .discription_subtitle1, .plan-2:hover .discription_subtitle2, .plan-3:hover .discription_subtitle3{
  color: white;
  }
  
  
  .price1,.price2,.price3{
    font-family: Inter;
    font-size: 20.74px;
    font-weight: 600;
    line-height: 28.73px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .white_check1,.white_check2,.white_check3{
    display: none !important;
  }
  .price_details{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    margin-bottom: 10px 
  }
  .price_interval,.price_interva2,.price_interva3{
    color:#84819994 ;
  }
  
  .plan-1:hover .price_interval,.plan-2:hover .price_interval2,.plan-3:hover .price_interval3{
  
  color: white;
  }
  
  
  
  
  .discription1,.discription2,.discription3{
    font-family: Inter;
    font-size: 10.25px;
    font-weight: 500;
    line-height: 19.37px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
  }
  
  .discription_subtitle{
    color: #282829;
  }
  
  
  .ponits_list1,.ponits_list2,.ponits_list3{
    font-family: Inter;
    font-size: 12.25px;
    font-weight: 500;
    line-height: 23.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    
  }


  .ponits_list1 img ,.ponits_list2 img,.ponits_list3 img{
     margin-top: 5px;
    height: fit-content;
  }
  
  .points_list_full{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  .plan-1:hover .choose_plan1,.plan-2:hover .choose_plan2,.plan-3:hover .choose_plan3{
    background-color: white;
    color: #4447E0;
  }
  .choose_plan1,.choose_plan2,.choose_plan3{
   background-color: #4447E0;
   font-size: 1.2em;
   color: white;
   border: 1px solid gray;
   border-radius: 10px; 
   padding: 8px;
   width: 100%;
   align-self:center;
    
  }
  .MOSTPOPULAR{
   background-color: white;
   color: linear-gradient(180deg, #762ADF 0%, #32A9C6 100%);
    border: none;
     border-radius: 20px;
     text-align: right;
     width: fit-content;
     padding: 8px;
    font-family: Outfit;
    font-weight: 800;
    justify-self: end; 
  }
  .footer{
    background-color: black;
  }

  .footer_terms{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    font-size: 0.8em;
    color: white;
    font-family: Outfit;
    border-top:1px solid rgba(255, 255, 255, 0.24) ;
  }
  
  
  .footer_top{
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    justify-self: center;
    width: 100%;
    padding: 20px;
  }
  
  .form_footer{
    width: 100%;
    border-right:none !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .product_footer{
    border-right:none  !important;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .contact_footer{
    
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .footer_headings{
    color: white;
    font-family: Outfit;
    font-size: 1.5em;
    font-weight: 600;
  }
  .footer_subheadings{
    color: white;
    font-size: 0.8em;
  }

}












/* BlogGrid.css */

/* Container for the blog grid */
.blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 40px;
  justify-items: center; 
  align-items: center;
  background-color: transparent;
}

.blog-card {
  width: 300px;
  height: 340px;
  border: 1px solid  #32A9C6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color:#282828;
  color: white;
}

.blog-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card .card-content {
  padding: 10px;
  flex-grow: 1;
}

.blog-card h3 {
  font-size: 18px;
  color: white;
  margin: 10px 0;
}

.blog-card p {
  font-size: 14px;
  color: white;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1140px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}





/* Styling for blogspage_top_blogsfull */
.blogspage_top_blogsfull {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 70%;
  background-color: #282828;
  border: none;
  border-radius: 10px;
  margin: 20px auto; /* This ensures it centers horizontally within its parent */
}

.blogspage_top_content_blogsfull, .blogspage_top_image_blogsfull {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogspage_top_content_blogsfull {
  flex: 1;
  flex-direction: column;
  text-align: left;
  color: white;
}

.blogspage_top_image_blogsfull img {
  width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

/* Mobile responsiveness for smaller screens */
@media screen and (max-width: 1140px) {
  .blogspage_top_blogsfull {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 95%;
  }

  .blogspage_top_content_blogsfull,
  .blogspage_top_image_blogsfull {
      flex: none;
      width: 100%;
  }


  .blog-page_blogsfull {
    padding: 8%;
    max-width: 100%;
    margin: 0 auto;
    background:white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
  
  }
  .blog-image-container_blogsfull {
    width: 100% !important;
    
    display: flex;
    justify-content: left;
  }

  .blog-title_blogsfull {
    font-size: 2.5rem;
   
  }
  .blog-title_blogsfull {
    font-size: 1.5rem !important;
    margin-bottom: 10px !important;
  }

}

/* Styling for blog-page_blogsfull */
.blog-page_blogsfull {
  padding: 8%;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: black;

}

.blog-title_blogsfull {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.blog-image-container_blogsfull {
  width: 340px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.blog-image_blogsfull {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-content_blogsfull {
  font-family: Arial, sans-serif;
  line-height: 2  ;
  color:black;
  font-size: 1.1rem;
  margin-top: 20px;
}
