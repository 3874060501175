/* Popup.css */
.popup {
  position: fixed;
  top: 40px; /* Change 'bottom' to 'top' */
  
  background-color:  var(--main-color); /* Background color */
  color: white;
  padding: 15px;
  border-radius: 5px;
  transition: opacity 0.5s ease;
  font-family: Noto Sans;
}
  