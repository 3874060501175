.background_leftPanel{
    background-color: #4447E0;
    max-width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;    
    padding: 0px 0px 10px 10px; 
    position: fixed;    
}
@media (max-width: 1124px) {
    .background_leftPanel{
        display: none;
    }
}
.top_leftPanel{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.icon_title_leftpanel{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 10px;
color: white;
font-size: 1.5em;
width: 100%;
}

.icon_leftpanel{
    width: 5%px;
}
.buy_leftPanel{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: white;
    padding: 10px 30px;
    border: none;
    border-radius: 50px;
    width: 90%;
}
.buttons_leftPanel{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
   
}
.nav_buttons_leftpanle {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    text-align: left;
    color: white;
    background-color: transparent;
    border: none;
    padding: 10px;
}
#login_leftPanel{
    display: flex;
justify-content: center;
justify-self: center;
align-self: center;
color: white;
padding: 10px 10px;
border: 1px solid white;
width: 80%;
border-radius: 10px;
background-color: transparent;
}

#login_leftPanel div{
    position: absolute; /* Position it directly below the button */
    top: 100%; /* Align the top of the dropdown below the button */
    left: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 150px;
    margin-top: 8px; /* Optional: adds some space between button and dropdown */
    z-index: 10;
    display: block;
}




/* Dropdown container */
.google-auth-container {
    position: relative; /* Ensure the dropdown is positioned relative to the button */
}

/* Button styling */
.dropdown-btn {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* User profile picture styling */
.user-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
}

/* Dropdown icon styling (arrow next to name) */
.dropdown-icon {
    margin-left: 8px; /* Space between name and icon */
    display: flex;
    align-items: center;
}

/* Dropdown menu positioning */
.dropdown-menu {
    position: absolute;
    bottom: 100%; /* Position the dropdown directly above the button */
    right: 10%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 100px;
    margin-bottom: 8px; /* Adds some space between the button and dropdown */
    z-index: 10;
    display: block; /* Ensure it's shown when dropdown is open */
}

/* List styling */
.dropdown-menu ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

/* Dropdown item styling */
.dropdown-menu li {
    padding: 10px;
    text-align: left;
    cursor: pointer;
}


.dropdown-menu button{

background-color: transparent;
border: none;
}




.nav_buttons_leftpanle:hover {
    color:var(--main-color); 
    background-color: white;
 
    border-radius: 10px;
}

.nav_buttons_leftpanle:hover .scroll-img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(1935%) hue-rotate(225deg) brightness(114%) contrast(80%);
}

.nav_buttons_leftpanle.active .scroll-img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(1935%) hue-rotate(225deg) brightness(114%) contrast(80%);
}

.nav_buttons_leftpanle.active {
    color: var(--main-color);
    background-color: white;
 
    border-radius: 10px;
   
    
}