.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white; /* Semi-transparent dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999; /* Ensures loader is above everything */
    color: white;
    font-size: 18px;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
